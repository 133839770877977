.footer-container {
  display: flex;
  justify-content: center;
  align-items: center;
  background: white;
  width: 100%;
  height: 50px;
}
.footer-container p {
  font-size: 14px;
  margin: 0;
}
.footer-container span {
  padding-left: 10px;
  margin-left: 10px;
  border-left: 1px solid black;
}

@media only screen and (max-width: 970px) {
  .footer-container p {
    font-size: 16px;
  }
}
