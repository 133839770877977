.about-content-container {
  display: flex;
  /*justify-content: space-around;*/
  justify-content: center;
  width: 100%;
  min-height: 500px;
}
.about-text-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  /*width: 50%;*/
  width: 60%;
  padding: 30px 6% 60px 6%;
}
.about-text-container p {
  text-align: justify;
  padding-top: 10px;
}
.intro-bigline {
  font-size: 30px;
  font-weight: 600;
  padding-bottom: 20px;
  text-align: center;
}
.about-text-container li::marker {
  color: #46b4e9;
  content: "+ ";
  font-weight: 600;
  font-size: 20px;
}
.about-text-container ul {
  padding-bottom: 40px;
  text-align: justify;
}
.intro-subline {
  color: #46b4e9;
  font-weight: 600;
}
.about-image-container {
  display: none;
}

/*
.about-image-container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 6%;
}
.about-image {
  height: 500px;
  width: 700px;
  background-image: url("../../Assets/About/placeholder.jpg");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}
*/

/*RESPONSIVENESS*/
@media only screen and (max-width: 1500px) {
  .about-image {
    height: 400px;
    width: 550px;
  }
  .about-text-container {
    /*padding-left: 0;*/
  }
}
@media only screen and (max-width: 970px) {
  .about-content-container {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .about-text-container {
    width: 100%;
    padding-left: 6%;
    padding-top: 20px;
  }
  .about-text-container p {
    text-align: justify;
    padding-bottom: 40px;
  }
}
