.medical-container {
  width: 100%;
}
.mc-services-title .separator-line {
  background-color: #1d3875;
}
.mc-services-bullets li::marker {
  content: "+ ";
  font-weight: 600;
  font-size: 20px;
}
.medical-container li::marker {
  color: #1d3875;
}
.mc-content-image {
  height: 413px;
  width: 100%;
  background-image: url("../../Assets/Services/mc_img.jpeg");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: left;
}
.mc-services-bullets span {
  font-weight: 600;
  color: #1d3875;
}

/*RESPONSIVENESS*/
@media only screen and (max-width: 1110px) {
  .mc-content-image {
    background-position: center;
    height: 650px;
  }
}
@media only screen and (max-width: 900px) {
  .mc-content-image {
    height: 500px;
  }
}
@media only screen and (max-width: 650px) {
  .mc-content-image {
    height: 350px;
  }
}

@media only screen and (max-width: 500px) {
  .mc-content-image {
    height: 280px;
  }
}
