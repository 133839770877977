.SP-container {
  background-color: #fafafa;
}
.SP-content-container {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  width: 100%;
  min-height: 600px;
}

.SP-left,
.SP-right {
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 0 6%;
}
.SP-image1 {
  background-image: url("../../Assets/Specialty/FACIAL-CENTER-PNG.png");
}
.SP-image2 {
  background-image: url("../../Assets/Specialty/MED-ESTETICA-PNG.png");
}
.SP-image3 {
  background-image: url("../../Assets/Specialty/MEDICAL-CENTER-PNG.png");
}
.SP-image4 {
  background-image: url("../../Assets/Specialty/ORAL-CENTER-PNG.png");
}

.SP-image1,
.SP-image2,
.SP-image3,
.SP-image4 {
  height: 300px;
  width: 500px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

.SP-image1:hover,
.SP-image2:hover,
.SP-image3:hover,
.SP-image4:hover {
  transform: scale(1.07);
  transition: 1s ease-out;
  cursor: pointer;
}

/*RESPONSIVENESS*/
@media only screen and (max-width: 970px) {
  .SP-left,
  .SP-right {
    flex-direction: column;
  }
  .SP-image1,
  .SP-image2,
  .SP-image3,
  .SP-image4 {
    height: 300px;
    width: 400px;
  }
}
