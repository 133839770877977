.header-container {
  display: flex;
  justify-content: center;
  height: 90px;
}
.header-parent {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 100%;
  width: 100%;
  padding: 0 6%;
}
.logo-small {
  height: 70px;
  width: 160px;
  background-image: url("../../../Assets/Home/HEALTH-CENTER-PNGsmall.png");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}
.header-options {
  display: flex;
  align-items: center;
}
.header-option {
  transform: scale(1.04);
  transition: 1s ease-out;
  font-weight: 600;
}
.heads {
  padding: 0 20px;
  cursor: pointer;
  color: black;
}
.heads:hover {
  font-weight: 600;
}
.header-menu {
  display: none;
}

/*RESPONSIVENESS*/
@media only screen and (max-width: 1110px) {
}
@media only screen and (max-width: 970px) {
  .header-menu {
    display: block;
  }
  .header-parent {
    width: 100%;
    position: relative;
  }
  .header-options {
    display: flex;
    flex-direction: column;
    position: absolute;
    left: -100%;
    transition: all 0.8s ease-out;
    top: 0px;
    height: 100vh;
    width: 100%;
    justify-content: space-around;
    background-color: #1d3875;
    z-index: 1000;
    opacity: 0.5;
  }
  .header-menu-bars {
    font-size: 36px;
    cursor: pointer;
    margin: 0px 10px;
  }
  .show-menu-options {
    left: 0;
    opacity: 1;
  }
  .heads {
    color: white;
  }
}
