.med-container {
  width: 100%;
}
.me-services-title .separator-line {
  background-color: #e05591;
}
.med-container li::marker {
  color: #e05591;
}
.me-content-image {
  height: 600px;
  width: 100%;
  background-image: url("../../Assets/Services/me_img.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

/*RESPONSIVENESS*/
@media only screen and (max-width: 1600px) {
  .me-content-image {
    background-position: left;
  }
}
@media only screen and (max-width: 900px) {
  .me-content-image {
    height: 400px;
    background-position: center;
  }
}
@media only screen and (max-width: 500px) {
  .me-content-image {
    background-size: contain;
    height: 280px;
  }
}
