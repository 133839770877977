.title-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 30px 0 20px 0;
}
.title {
  font-size: 32px;
  color: #1f2235;
}
.title-separator {
  display: flex;
  align-items: center;
  position: relative;
  width: 200px;
  margin: 10px 0 0 0;
}
.separator-line {
  width: 100%;
  height: 3px;
  background-color: #46b4e9;
}
