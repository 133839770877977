.intro-container {
  display: flex;
  justify-content: space-around;
  width: 100%;
  min-height: 700px;
  background-image: url("../../../Assets/Home/bg1.jpeg");
  background-attachment: fixed;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  padding-bottom: 40px;
}
.intro-left-container {
  display: flex;
  align-items: center;
  width: 50%;
  padding: 0 4%;
}
.intro-blur {
  display: flex;
  flex-direction: column;
  align-items: center;
  background: rgba(250, 250, 250, 0.5);
  border: none;
  border-radius: 30px;
  padding-top: 20px;
  padding-bottom: 20px;
}
.intro-text-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0 6%;
}
.intro-left-container p {
  text-align: justify;
  padding-top: 10px;
}
.btn {
  height: 60px;
  width: 150px;
  background: #46b4e9;
  border-radius: 15px;
}
.btn:hover {
  color: white;
  background: #1d3875;
}
.logo-container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 6%;
}
.logo-big {
  height: 500px;
  width: 700px;
  background-image: url("../../../Assets/Home/HEALTH-CENTER-PNG.png");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}
.logo-big:hover {
  transform: scale(1.07);
  transition: 1s ease-out;
}

/*RESPONSIVENESS*/
@media only screen and (max-width: 1500px) {
  .logo-big {
    height: 400px;
    width: 600px;
  }
  .logo-container {
    padding: 0 4%;
  }
  .intro-text-container {
    /*min-width: 380px;*/
    width: 100%;
  }
  .intro-left-container {
    padding-right: 0;
  }
}
@media only screen and (max-width: 1220px) {
  .logo-big {
    height: 350px;
    width: 500px;
  }
}
@media only screen and (max-width: 1090px) {
  .intro-container {
    flex-direction: column-reverse;
    justify-content: center;
    align-items: center;
  }
  .intro-left-container {
    width: 100%;
    padding-right: 6%;
    padding-left: 6%;
  }
  .intro-left-container p {
    text-align: justify;
    padding-top: 0px;
  }
  .btn {
    height: 70px;
    width: 250px;
    margin-top: 20px;
  }
}
@media only screen and (max-width: 450px) {
  .logo-big {
    height: 250px;
    width: 370px;
  }
}
