.oral-container {
  width: 100%;
}
.oc-services-title .separator-line {
  background-color: #0578bd;
}
.oc-content-image {
  height: 600px;
  width: 100%;
  background-image: url("../../Assets/Services/oc_img.jpeg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}
.oc-services-bullets span {
  font-weight: 600;
  color: #0578bd;
}

/*RESPONSIVENESS*/
@media only screen and (max-width: 1600px) {
  .oc-content-image {
  }
}
@media only screen and (max-width: 900px) {
  .oc-content-image {
    height: 450px;
    background-position: center;
  }
}
@media only screen and (max-width: 500px) {
  .oc-content-image {
    background-size: contain;
    height: 320px;
  }
}
