.contact-container {
  min-height: 350px;
  width: 100%;
  /*background-color: #46b4e9;*/
  background-color: #1d3875;
}
.contact-wide {
  display: flex;
  justify-content: space-between;
}
.contact-left-container {
  display: flex;
  flex-direction: column;
  padding-left: 4%;
}

/*Title*/
.contact-title-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 30px 0 20px 0;
}
.contact-title {
  font-size: 32px;
  color: white;
}
.contact-title-separator {
  display: flex;
  align-items: center;
  position: relative;
  width: 200px;
  margin: 10px 0 0 0;
}
.contact-separator-line {
  width: 100%;
  height: 3px;
  background-color: white;
}
/*End of Title*/

.contact-content-container {
  display: flex;
  justify-content: space-between;
  width: 900px;
  color: white;
}

.contact-content-text {
  display: flex;
  flex-direction: column;
}

.contact-address-title {
  font-size: 24px;
}
.contact-text {
  padding-top: 20px;
}
.contact-text p {
  margin: 0;
}

.contact-buttons {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}
.contact-buttons i {
  font-size: 36px;
}
.btn-email,
.btn-phone,
.btn-map {
  width: 300px;
  height: 90px;
  border: none;
  border-radius: 10px;
  margin: 10px;
  font-weight: 600;
}
.btn-email:hover,
.btn-phone:hover,
.btn-map:hover {
  cursor: pointer;
  transform: scale(1.03);
}

.btn-phone-text {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-left: 10px;
}

.btn-email span,
.btn-map span {
  padding-left: 10px;
}
.btn-email {
  background-color: #46b4e9;
  color: white;
}
.btn-phone {
  background-color: #ed4d91;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
}
.btn-phone-num {
  font-weight: 400;
}
.btn-map {
  background-color: #6efaf0;
}

.contact-image {
  height: 530px;
  width: 700px;
  background-image: url("../../Assets/Contact/arcemappin.png");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: right;
}

/*Home Button*/
.footer-scroll-container {
  position: absolute;
  text-align: center;
  right: 0;
  margin-right: 20px;
  overflow: hidden;
  flex-direction: column;
  margin-top: -25px;
  z-index: 100;
  cursor: pointer;
}
@media only screen and (min-width: 475px) {
  .scroll-container {
    margin-bottom: 10px;
  }
}
.btn-scroll {
  width: 50px;
  height: 50px;
  color: white;
  font-size: 24px;
  background-color: #ed4d91;
  border-radius: 10px;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
}

.btn-scroll i {
  -webkit-animation: mover 0.5s infinite alternate;
  animation: mover 0.5s infinite alternate;
}
@keyframes mover {
  100% {
    transform: translateY(-5px);
  }
  0% {
    transform: translateX(0px);
  }
}
/*End of Home Button*/

/*RESPONSIVENESS*/
@media only screen and (max-width: 1680px) {
  .contact-content-container {
    max-width: 700px;
  }
}

@media only screen and (max-width: 1460px) {
  .contact-content-container {
    flex-direction: column;
    width: 560px;
  }
  .contact-buttons {
    flex-direction: row;
    justify-content: flex-start;
    margin-top: 20px;
  }
  .contact-buttons i {
    font-size: 24px;
  }
  .btn-email,
  .btn-phone,
  .btn-map {
    width: 180px;
  }
}

@media only screen and (max-width: 1325px) {
  .contact-wide {
    flex-direction: column;
  }
  .contact-image {
    background-position: center;
  }
  .contact-right-container {
    display: flex;
    justify-content: center;
  }
  .contact-left-container {
    align-items: center;
  }
  .contact-title-container {
    align-items: center;
  }
  .contact-address-title {
    display: flex;
    justify-content: center;
  }
  .contact-text {
    padding-left: 200px;
  }
}
@media only screen and (max-width: 690px) {
  .contact-content-container {
    width: 100%;
  }
  .contact-left-container {
    padding: 0 6%;
  }
  .contact-buttons {
    flex-direction: column;
    align-items: center;
  }
  .contact-buttons i {
    font-size: 36px;
  }
  .btn-email,
  .btn-phone,
  .btn-map {
    width: 340px;
  }
  .contact-image {
    height: 350px;
    width: 450px;
    background-position: bottom;
  }
  .contact-text {
    padding-left: 0;
  }
  .footer-scroll-container {
    margin-right: 10px;
  }
  .btn-scroll {
    height: 40px;
    width: 40px;
  }
}
