.nav-container {
  display: flex;
  align-items: center;
  height: 100px;
  justify-content: space-between;
  padding-left: 6%;
  padding-right: 40%;
}

.nav-logo-small {
  height: 100px;
  width: 210px;
  object-fit: contain;
}
.nav-back {
  font-size: 40px;
  cursor: pointer;
}

/*RESPONSIVENESS*/
@media only screen and (max-width: 700px) {
  .nav-container {
    padding-right: 20%;
  }
}
