.facial-container {
  width: 100%;
}
.fc-services-title .separator-line {
  background-color: #4cc8bc;
}
.facial-container li::marker {
  color: #4cc8bc;
}
.fc-services-bullets li::marker {
  content: "+ ";
  font-weight: 600;
  font-size: 20px;
}

.fc-content-top {
  display: flex;
  align-items: center;
}
.fc-content-image {
  height: 600px;
  width: 100%;
  background-image: url("../../Assets/Services/fc_img.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}
.fc-content-bio span {
  font-size: 50px;
}
.fc-content-bio p {
  text-align: justify;
}
.fc-content-bio {
  padding: 0 6% 0 40px;
  width: 100%;
}

.fc-services {
  background-color: #fafafa;
  min-height: 600px;
}
.fc-services-container {
  padding: 40px 6% 60px 6%;
  font-size: 18px;
  text-align: justify;
}
.fc-services-bullets span {
  font-weight: 600;
  color: #4cc8bc;
}

/*RESPONSIVENESS*/
@media only screen and (max-width: 1110px) {
  .fc-content-top {
    flex-direction: column;
  }
  .fc-content-bio {
    padding: 40px 6% 40px 6%;
  }
  .fc-services-container {
    font-size: 16px;
  }
}
@media only screen and (max-width: 900px) {
  .fc-content-image {
    height: 400px;
  }
  .fc-content-bio span {
    font-size: 32px;
  }
}
@media only screen and (max-width: 500px) {
  .fc-content-image {
    background-size: contain;
    height: 280px;
  }
  .fc-content-bio {
    padding-top: 20px;
  }
  .fc-content-bio span {
    font-size: 28px;
  }
}
